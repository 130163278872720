import React from "react";
import s from "./Footer.module.css";

import {
  FlexContainer,
  FlexItem,
  WrappableFlexContainer,
  FixedFlexItem,
} from "../Flex";
import Container from "../Container";
import Spacing from "../Spacing";
import Link from "../Link";
import SlimFooter from "../SlimFooter";
import LegalFooter from "../LegalFooter";
import PreFooter from "../PreFooter";

const DOMAIN = "https://www.chaos.com";

const SOCIAL_ICONS = {
  facebook: {
    id: "FB-footer",
    link: "https://www.facebook.com/chaosgroup",
    icon: (
      <React.Fragment>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M15.664 4.65292H17.6724V1.15492C17.3259 1.10725 16.1343 1 14.7464 1C11.8507 1 9.86701 2.82142 9.86701 6.16908V9.25H6.67151V13.1605H9.86701V23H13.7848V13.1614H16.8511L17.3378 9.25092H13.7839V6.55683C13.7848 5.42658 14.0892 4.65292 15.664 4.65292Z"
            fill="#1C1C1C"
          />
        </svg>
      </React.Fragment>
    ),
  },
  twitter: {
    id: "TW-footer",
    link: "https://twitter.com/chaosgroup",
    icon: (
      <React.Fragment>
        <svg width="24" height="24" x="0px" y="0px" viewBox="0 0 1200 1227">
          <path
            d="M689.1,540.3l348.5-405.1h-82.6L652.3,486.9L410.7,135.2H131.9L497.4,667l-365.5,424.8h82.6l319.5-371.5
	l255.2,371.5h278.7L689.1,540.3L689.1,540.3z M576,671.7l-37.1-53L244.3,197.3h126.8l237.8,340.1l37.1,53L955,1032.5H828.2
	L576,671.7L576,671.7z"
            fill="#1C1C1C"
          />
        </svg>
      </React.Fragment>
    ),
  },
  instagram: {
    id: "IG-footer",
    link: "https://www.instagram.com/chaosgroup/",
    icon: (
      <React.Fragment>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12.0035 6.35156C8.88406 6.35156 6.35864 8.87973 6.35864 11.9964C6.35864 15.1158 8.88681 17.6412 12.0035 17.6412C15.1229 17.6412 17.6483 15.1131 17.6483 11.9964C17.6483 8.87698 15.1201 6.35156 12.0035 6.35156ZM12.0035 15.6603C9.97856 15.6603 8.33956 14.0204 8.33956 11.9964C8.33956 9.9724 9.97948 8.33248 12.0035 8.33248C14.0275 8.33248 15.6674 9.9724 15.6674 11.9964C15.6683 14.0204 14.0284 15.6603 12.0035 15.6603Z"
            fill="#1C1C1C"
          />
          <path
            d="M16.5356 1.06984C14.5116 0.97542 9.49838 0.980004 7.47255 1.06984C5.69238 1.15325 4.12213 1.58317 2.85621 2.84909C0.740547 4.96475 1.01096 7.81559 1.01096 11.9965C1.01096 16.2755 0.772631 19.0603 2.85621 21.1439C4.98013 23.2669 7.87221 22.9892 12.0036 22.9892C16.2423 22.9892 17.7053 22.9919 19.204 22.4117C21.2418 21.6206 22.78 19.7992 22.9303 16.5276C23.0256 14.5027 23.0201 9.49034 22.9303 7.4645C22.7488 3.60259 20.6762 1.2605 16.5356 1.06984ZM19.7394 19.7442C18.3525 21.1311 16.4284 21.0073 11.977 21.0073C7.39371 21.0073 5.5558 21.0752 4.21471 19.7304C2.67013 18.1932 2.94971 15.7246 2.94971 11.9818C2.94971 6.91725 2.42996 3.26984 7.51288 3.0095C8.68071 2.96825 9.02446 2.9545 11.9642 2.9545L12.0055 2.982C16.8904 2.982 20.723 2.4705 20.953 7.5525C21.0053 8.71209 21.0172 9.06042 21.0172 11.9956C21.0163 16.5258 21.1025 18.3747 19.7394 19.7442Z"
            fill="#1C1C1C"
          />
          <path
            d="M17.8719 7.44773C18.6005 7.44773 19.191 6.85716 19.191 6.12865C19.191 5.40014 18.6005 4.80957 17.8719 4.80957C17.1434 4.80957 16.5529 5.40014 16.5529 6.12865C16.5529 6.85716 17.1434 7.44773 17.8719 7.44773Z"
            fill="#1C1C1C"
          />
        </svg>
      </React.Fragment>
    ),
  },
  youtube: {
    id: "YT-footer",
    link: "https://www.youtube.com/chaosgrouptv",
    icon: (
      <React.Fragment>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M22.5133 6.43485L22.5408 6.61451C22.2749 5.67126 21.5572 4.94343 20.6451 4.67851L20.6259 4.67393C18.9108 4.20826 12.0092 4.20826 12.0092 4.20826C12.0092 4.20826 5.12503 4.1991 3.39253 4.67393C2.46303 4.94343 1.74436 5.67126 1.48311 6.59526L1.47853 6.61451C0.837776 9.96126 0.833192 13.9827 1.50694 17.5659L1.47853 17.3844C1.74436 18.3277 2.46211 19.0555 3.37419 19.3204L3.39344 19.325C5.10669 19.7916 12.0101 19.7916 12.0101 19.7916C12.0101 19.7916 18.8934 19.7916 20.6268 19.325C21.5572 19.0555 22.2759 18.3277 22.5371 17.4037L22.5417 17.3844C22.8332 15.8279 23 14.0368 23 12.2071C23 12.1402 23 12.0723 22.9991 12.0045C23 11.9422 23 11.8679 23 11.7937C23 9.9631 22.8332 8.17193 22.5133 6.43485ZM9.80736 15.3467V8.66143L15.5512 12.0091L9.80736 15.3467Z"
            fill="#1C1C1C"
          />
        </svg>
      </React.Fragment>
    ),
  },
  linkedin: {
    id: "IN-footer",
    link: "https://www.linkedin.com/company/chaos-group",
    icon: (
      <React.Fragment>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M23.1377 22.3655L23.1432 22.3646V15.2961C23.1432 11.3489 22.2935 8.30835 17.679 8.30835C15.4606 8.30835 13.972 9.52568 13.3642 10.6798H13.3001V8.67685H8.9248V22.3646H13.4806V16.0918C13.4806 14.1769 13.8436 12.3252 16.2151 12.3252C18.5516 12.3252 18.5865 14.5105 18.5865 16.2146V22.3655H23.1377Z"
            fill="#1C1C1C"
          />
          <path
            d="M1.36328 8.67773H5.92462V22.3655H1.36328V8.67773Z"
            fill="#1C1C1C"
          />
          <path
            d="M3.64183 1C2.18342 1 1 2.18342 1 3.64183C1 5.10025 2.18342 6.30842 3.64183 6.30842C5.10025 6.30842 6.28367 5.10025 6.28367 3.64183C6.28275 2.18342 5.09933 1 3.64183 1V1Z"
            fill="#1C1C1C"
          />
        </svg>
      </React.Fragment>
    ),
  },
  blogger: {
    id: "BL-footer",
    link: "http://vraymasters.cn/",
    icon: (
      <React.Fragment>
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <title>blogger-black</title>
          <desc>Created with Sketch.</desc>
          <g
            id="blogger-black"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="blogger" fill="#000000" fillRule="nonzero">
              <path
                d="M21.976,24 L2.026,24 C0.9,24 0,23.1 0,21.976 L0,2.026 C0,0.9 0.9,0 2.025,0 L22.05,0 C23.1,0 24,0.9 24,2.025 L24,21.975 C24,23.1 23.1,24 21.976,24 Z M12,3.975 L9,3.975 C6.225,3.975 3.975,6.225 3.975,9 L3.975,15 C3.975,17.774 6.225,20.024 9,20.024 L15,20.024 C17.774,20.024 20.024,17.774 20.024,15 L20.024,11.025 C20.024,10.425 19.574,9.975 18.974,9.975 L18,9.975 C17.476,9.975 17.024,9.525 17.024,8.999 C17.024,6.223 14.774,3.973 12,3.973 L12,3.975 Z M15.074,15.975 L9,15.975 C8.475,15.975 8.025,15.525 8.025,15 C8.025,14.475 8.475,14.024 9,14.024 L15.074,14.024 C15.6,14.024 16.051,14.474 16.051,15 C16.051,15.526 15.601,15.976 15.076,15.976 L15.074,15.975 Z M12.524,8.025 C13.051,8.025 13.5,8.475 13.5,9 C13.5,9.525 13.05,9.975 12.525,9.975 L8.925,9.975 C8.4,9.975 7.949,9.525 7.949,9 C7.949,8.475 8.399,8.025 8.924,8.025 L12.524,8.025 Z"
                id="Shape"
              />
            </g>
          </g>
        </svg>
      </React.Fragment>
    ),
  },
  youku: {
    id: "YK-footer",
    link: "http://i.youku.com/i/UMzI1MDU0Njc2NA==",
    icon: (
      <React.Fragment>
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <title>youku-black</title>
          <desc>Created with Sketch.</desc>
          <g
            id="youku-black"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="youtube"
              transform="translate(0.000000, 3.000000)"
              fill="#000000"
              fillRule="nonzero"
            >
              <path
                d="M23.495,3.205 C23.212191,2.19165677 22.4203432,1.39980902 21.407,1.117 C19.537,0.616 12.011,0.616 12.011,0.616 C12.011,0.616 4.504,0.606 2.615,1.117 C1.60165677,1.39980902 0.809809023,2.19165677 0.527,3.205 C0.172520594,5.11951056 -0.00223945169,7.06296279 0.005,9.01 C-0.000870868833,10.9496902 0.173883448,12.8857136 0.527,14.793 C0.809809023,15.8063432 1.60165677,16.598191 2.615,16.881 C4.483,17.383 12.011,17.383 12.011,17.383 C12.011,17.383 19.517,17.383 21.407,16.881 C22.4203432,16.598191 23.212191,15.8063432 23.495,14.793 C23.8407392,12.8850228 24.0081279,10.949005 23.995,9.01 C24.0094964,7.06365058 23.8421022,5.12020397 23.495,3.205 L23.495,3.205 Z M9.609,12.601 L9.609,5.408 L15.873,9.01 L9.609,12.601 Z"
                id="Shape"
              />
            </g>
          </g>
        </svg>
      </React.Fragment>
    ),
  },
  weibo: {
    id: "WB-footer",
    link: "http://www.weibo.com/5676229613/profile?topnav=1&wvr=6&is_all=1",
    icon: (
      <React.Fragment>
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <title>weibo-black</title>
          <desc>Created with Sketch.</desc>
          <g
            id="weibo-black"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="sinaweibo"
              transform="translate(0.000000, 2.000000)"
              fill="#000000"
              fillRule="nonzero"
            >
              <path
                d="M10.098,18.323 C6.121,18.714 2.684,16.917 2.426,14.303 C2.167,11.694 5.185,9.256 9.166,8.862 C13.145,8.468 16.579,10.266 16.837,12.88 C17.096,15.48 14.078,17.929 10.1,18.319 L10.098,18.323 Z M9.05,15.219 C8.666,15.835 7.842,16.103 7.221,15.821 C6.609,15.542 6.428,14.83 6.815,14.228 C7.194,13.633 7.991,13.367 8.608,13.627 C9.23,13.89 9.428,14.599 9.05,15.219 Z M10.32,13.592 C10.179,13.829 9.871,13.945 9.631,13.845 C9.395,13.755 9.318,13.484 9.454,13.259 C9.592,13.032 9.89,12.913 10.126,13.019 C10.365,13.109 10.441,13.379 10.306,13.62 L10.32,13.592 Z M10.496,10.873 C8.603,10.38 6.463,11.323 5.639,12.991 C4.803,14.695 5.613,16.582 7.525,17.201 C9.508,17.841 11.843,16.86 12.657,15.022 C13.457,13.229 12.456,11.38 10.496,10.873 Z M18.059,9.649 C17.713,9.544 17.489,9.469 17.654,9.034 C18.029,8.057 18.074,7.23 17.654,6.63 C16.873,5.518 14.739,5.577 12.29,6.6 C12.29,6.6 11.524,6.931 11.719,6.329 C12.095,5.112 12.034,4.105 11.449,3.52 C10.111,2.183 6.58,3.565 3.561,6.6 C1.309,8.87 0,11.273 0,13.348 C0,17.329 5.099,19.743 10.086,19.743 C16.622,19.743 20.974,15.942 20.974,12.923 C20.974,11.101 19.427,10.069 18.059,9.639 L18.059,9.649 Z M19.967,4.557 C19.201,3.701 18.059,3.37 17.007,3.595 C16.571,3.685 16.301,4.106 16.391,4.527 C16.481,4.947 16.902,5.218 17.323,5.129 C17.834,5.024 18.39,5.173 18.765,5.594 C19.141,6.015 19.231,6.571 19.081,7.067 C18.945,7.473 19.17,7.923 19.591,8.059 C19.996,8.178 20.448,7.954 20.583,7.547 C20.913,6.526 20.703,5.369 19.937,4.512 L19.967,4.557 Z M22.385,2.362 C20.809,0.605 18.48,-0.057 16.331,0.394 C15.835,0.498 15.519,0.981 15.625,1.475 C15.729,1.971 16.211,2.288 16.707,2.182 C18.239,1.851 19.892,2.332 21.003,3.565 C22.115,4.811 22.432,6.508 21.95,7.981 C21.785,8.461 22.056,8.988 22.536,9.138 C23.015,9.303 23.527,9.034 23.693,8.552 C24.368,6.464 23.934,4.074 22.355,2.317 L22.385,2.362 Z"
                id="Shape"
              />
            </g>
          </g>
        </svg>
      </React.Fragment>
    ),
  },
};

const LinkColumn = ({ title, links }) => (
  <div className={s.links}>
    <h6>{title}</h6>

    <ul>
      {links.map((l) => (
        <li key={l.id}>
          <Link settings={l.link} />
        </li>
      ))}
    </ul>
  </div>
);

const FooterColumn = ({ title, links, fullSet }) => {
  const ItemComponent = fullSet ? FixedFlexItem : FlexItem;

  return (
    title &&
    links.length > 0 && (
      <ItemComponent setWidth={fullSet}>
        <LinkColumn title={title} links={links} />
      </ItemComponent>
    )
  );
};

export default function Footer({
  data,
  user,
  locale,
  visibleProducts,
  hidePreFooter = false,
}) {
  const fullSet = [
    data.column_1_title,
    data.column_1_links.length > 0,
    data.column_2_title,
    data.column_2_links.length > 0,
    data.column_3_title,
    data.column_3_links.length > 0,
    data.column_4_title,
    data.column_4_links.length > 0,
    data.column_5_title,
    data.column_5_links.length > 0,
  ].every((item) => Boolean(item));

  const ContainerComponent = fullSet ? WrappableFlexContainer : FlexContainer;

  return (
    <div className={s.footer}>
      {data?.hubspot?.form_id && !hidePreFooter && (
        <PreFooter
          newsletter={data.newsletter}
          hubspotData={data.hubspot}
          user={user}
        />
      )}

      <SlimFooter
        locale={locale}
        domain={DOMAIN}
        visibleProducts={visibleProducts}
      />

      <Spacing s="both-sm">
        <Container>
          <ContainerComponent>
            <FooterColumn
              fullSet={fullSet}
              title={data.column_1_title}
              links={data.column_1_links}
            />

            <FooterColumn
              fullSet={fullSet}
              title={data.column_2_title}
              links={data.column_2_links}
            />

            <FooterColumn
              fullSet={fullSet}
              title={data.column_3_title}
              links={data.column_3_links}
            />

            <FooterColumn
              fullSet={fullSet}
              title={data.column_4_title}
              links={data.column_4_links}
            />

            <FooterColumn
              fullSet={fullSet}
              title={data.column_5_title}
              links={data.column_5_links}
            />

            <FlexItem>
              <div className={s.links}>
                <h6>{data.follow_us}</h6>

                <div className={s.social}>
                  {data.social_icons.map(
                    (l) =>
                      SOCIAL_ICONS[l.icon] && (
                        <a
                          key={l.icon}
                          href={SOCIAL_ICONS[l.icon].link}
                          id={SOCIAL_ICONS[l.icon].id}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div>{SOCIAL_ICONS[l.icon].icon}</div>
                        </a>
                      ),
                  )}
                </div>
              </div>
            </FlexItem>
          </ContainerComponent>
        </Container>
      </Spacing>

      <LegalFooter
        copyright={data.copyright}
        links={data.slim_links}
        cookieConsent={data.slim_cookie_consent}
      />
    </div>
  );
}
