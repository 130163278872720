import React from "react";
import Container from "../Container";
import s from "./SlimFooter.module.css";
import { LOGO } from "./LOGOS";
import PRODUCTS from "./PRODUCTS";

const pickLocale = (locale) => {
  const parsedLocale = (locale || "").toLowerCase();

  if (parsedLocale === "en") {
    return "";
  } else {
    return parsedLocale;
  }
};

const buildProductLink = (domain, link, locale) => {
  if (link.substring(0, 4) === "http") return link;

  const correctLocale = pickLocale(locale);

  if (correctLocale) {
    return `${domain}/${correctLocale}${link}`;
  } else {
    return `${domain}${link}`;
  }
};

const filterProducts = (products, visibleProducts) => {
  return products.filter((p) => visibleProducts[p.htmlID]);
};

const SlimFooter = ({ locale, domain, visibleProducts }) => (
  <div className={s["slim-wrapper"]}>
    <Container>
      <div className={s.slim}>
        <div className={s["logos-wrapper"]}>
          <a href={`https://www.chaos.com/${pickLocale(locale)}`}>
            <img src={LOGO} alt="Chaos" />
          </a>
        </div>

        <div className={s.products}>
          {filterProducts(PRODUCTS, visibleProducts).map((product) => (
            <a
              key={product.id}
              href={buildProductLink(domain, product.link, locale)}
              id={product.htmlID}
              target={product.target}
            >
              {product.logo}
            </a>
          ))}
        </div>
      </div>
    </Container>
  </div>
);

SlimFooter.defaultProps = {
  visibleProducts: {
    "footer-vray": true,
    "footer-cosmos": true,
    "footer-scans": true,
    "footer-corona": true,
    "footer-phoenix": true,
    "footer-vantage": true,
    "footer-chaos_player": true,
    "footer-chaos": true,
    "footer-enscape": true,
    "footer-cylindo": true,
  },
};

export default SlimFooter;
