import React from "react";
import fetch from "cross-fetch";

const withApiData = (Component, Placeholder) =>
  class ChaosLayoutWrapper extends React.Component {
    constructor(props) {
      super(props);

      this.state = { data: "" };
    }

    componentDidMount() {
      if (this.props.url) {
        fetch(this.props.url)
          .then((resp) => resp.json())
          .then((data) => this.setState({ data: data }));
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.url !== prevProps.url) {
        fetch(this.props.url)
          .then((resp) => resp.json())
          .then((data) => this.setState({ data: data }));
      }
    }

    render() {
      const { data } = this.state;
      const { url, prefetchedData, ...rest } = this.props;

      if (!data && !prefetchedData) {
        return <Placeholder {...rest} />;
      }

      if (prefetchedData) {
        return <Component data={{ ...prefetchedData }} {...rest} />;
      } else {
        return <Component data={{ ...data }} {...rest} />;
      }
    }
  };

export default withApiData;
