import React from "react";
import SlimFooter from "./SlimFooter";

const DOMAIN = "https://www.chaos.com";

const Placeholder = ({ locale }) => (
  <SlimFooter locale={locale} domain={DOMAIN} />
);

export default Placeholder;
