import React from "react";
import PlaceholderRectangle from "../PlaceholderRectangle";
import s from "./PreFooter.module.css";

import Container from "../Container";

const Placeholder = () => (
  <div className="pre-footer">
    <Container>
      <div className={s["pre-footer-wrapper"]}>
        <div className={s["text-wrapper"]}>
          <PlaceholderRectangle style={{ width: "200px", height: "42px" }} />
          <PlaceholderRectangle style={{ width: "200px", height: "32px" }} />
        </div>

        <div className={s["hubspot-overrides"]}>
          <PlaceholderRectangle style={{ width: "500px", height: "175px" }} />
        </div>
      </div>
    </Container>
  </div>
);

export default Placeholder;
