import React from "react";
import Container from "../Container";
import Link from "../Link";
import s from "./LegalFooter.module.css";

const CookieConsentBtn = ({ link, label, className, target, id }) => (
  <a
    href={link}
    title={label}
    className={`${className} ${s["cookie-consent"]}`}
    target={target}
    id={id}
    onClick={(e) => {
      e.preventDefault();
    }}
  >
    {label}
  </a>
);

const LegalFooter = ({ copyright, links, cookieConsent }) => (
  <div className={s["legal-wrapper"]}>
    <Container>
      <div className={s.legal}>
        <div className={s.copyright}>{copyright}</div>
        <div className={s["legal-links"]}>
          {links.map((l) => (
            <Link key={l.id} settings={l.link} />
          ))}
          {cookieConsent && <CookieConsentBtn {...cookieConsent} />}
        </div>
      </div>
    </Container>
  </div>
);

export default LegalFooter;
