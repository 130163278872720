import CartContext from "./CartContext";

const extractCartInfo = (link) => {
  if (!link) {
    return {};
  }

  const match = link.match(
    /#product-code-(?<productCode>.+)-qty-(?<quantity>\d+)/,
  );

  if (
    match &&
    match.groups &&
    match.groups.productCode &&
    match.groups.quantity
  ) {
    return {
      productCode: match.groups.productCode,
      quantity: match.groups.quantity,
    };
  }

  return {};
};

export { CartContext, extractCartInfo };
