import React from "react";

const CORONA = {
  id: "corona-renderer",
  htmlID: "footer-corona",
  name: "Corona renderer",
  link: "https://corona-renderer.com/",
  target: "_blank",
  logo: (
    <React.Fragment>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        width="40"
        height="40"
        viewBox="0 0 100 100"
        // style="enable-background:new 0 0 100 100;"
        xmlSpace="preserve"
      >
        <g id="Layer_1">
          <g>
            <path fill="#F3712B" d="M0,0v100h100V0H0z" />
            <path
              fill="#FFFFFF"
              d="M67.5859375,22.9960938c0.8706055,3.9125977-1.3916016,6.9240723-1.3916016,6.9240723
    C59.46875,19.1967773,54.0185547,17.1818848,49.5439453,17.0062256c2.5986328,4.338501-0.3125,8.0899658-0.4038086,8.0753174
    c-7.8774414-12.8620605-18.3105469-7.1517334-18.3105469-7.1517334s7.3686523,5.2301025,4.5258789,11.3636475
    c-10.9633789-7.3326416-18.6889648,0.2874756-18.6889648,0.2874756s11.6206055,4.6218262,8.7763672,11.81604
    c-2.0097656,4.6206055-7.730957,3.5612793-8.1430664,3.1965332c-0.0615234,3.2352295,1.5576172,13.3930664,11.4262695,18.7668457
    c0,0-3.5234375,2.4221191-8.0488281,1.288208c0.8637695,1.7593994,1.9477539,3.5412598,3.3232422,5.2965088
    C34.6655273,84.4733887,55.1870117,87.675293,69.8359375,77.095459
    c14.6474609-10.5783691,17.8740234-30.9316406,7.2089844-45.4624023
    C74.2822266,27.4489746,69.7783203,24.3469238,67.5859375,22.9960938z M67.2158203,71.9904785
    c-8.6113281,6.2202148-20.6772461,4.3371582-26.9472656-4.2052002c-0.1835938-0.2528076-0.3378906-0.5150146-0.5097656-0.7718506
    c-5.6176758-8.4798584-3.609375-19.9206543,4.7495117-25.9558105c8.4824219-6.1256104,20.3027344-4.3769531,26.6489258,3.8393555
    c0.0966797,0.1251221,0.2041016,0.2368164,0.2983398,0.3659668C77.7265625,53.8039551,75.828125,65.7705078,67.2158203,71.9904785
    z"
            />
          </g>
        </g>
        <g id="Isolation_Mode" />
      </svg>
    </React.Fragment>
  ),
};

const VRAY = {
  id: "vray",
  htmlID: "footer-vray",
  name: "V-Ray",
  link: "/3d-rendering-software",
  target: "",
  logo: (
    <React.Fragment>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M40 0H0V40H40V0Z" fill="#2857AF" />
        <path
          d="M16.3168 24.2834C16.5835 24.6667 16.9002 24.8834 17.1668 24.8667C17.4835 24.8501 17.8335 24.5334 18.1002 24.0501C18.9502 22.4667 19.5001 20.9167 20.0335 19.4334C20.7835 17.3334 21.5502 15.1667 23.1002 13.1167C24.7335 10.9834 27.3168 10.1501 28.2668 9.91675C28.4168 9.88341 28.4502 9.70008 28.3335 9.60008C26.0335 7.76675 23.1502 6.66675 20.0002 6.66675C18.4501 6.66675 16.9668 6.93341 15.5835 7.41675C13.8835 8.01675 12.7335 9.61675 12.6835 11.4167C12.6335 14.9167 13.1835 19.9001 16.3168 24.2834Z"
          fill="white"
        />
        <path
          d="M28.7168 13.0001C27.3002 13.1834 26.2502 14.2001 25.6335 15.0167C24.3835 16.6834 23.7502 18.4334 23.0168 20.4834C22.4335 22.0834 21.8502 23.7501 20.8835 25.5501C20.0835 27.0334 18.8002 28.5501 17.3668 28.6501C17.2835 28.6501 17.2168 28.6501 17.1335 28.6501C15.8335 28.6501 14.5835 27.3501 13.7168 26.1167C10.5002 21.6001 9.60016 16.6667 9.50016 12.8501C9.50016 12.5001 9.05016 12.3834 8.85016 12.6667C6.95016 15.4334 6.06683 19.3001 7.05016 23.2001C8.30016 28.1834 12.3335 31.9334 16.9668 32.9667C25.6335 34.9167 33.3168 28.3167 33.3168 19.9667C33.3168 18.2834 33.0002 16.6834 32.4335 15.2001C32.0168 14.1167 31.1002 13.2834 29.9668 13.0334C29.5668 12.9667 29.1335 12.9501 28.7168 13.0001Z"
          fill="white"
        />
      </svg>
    </React.Fragment>
  ),
};

const COSMOS = {
  id: "cosmos",
  htmlID: "footer-cosmos",
  name: "Chaos Cosmos",
  link: "/cosmos",
  target: "",
  logo: (
    <React.Fragment>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M40 0H0V40H40V0Z" fill="#714AB5" />
        <path
          d="M20 6.66675C12.6333 6.66675 6.66663 12.6334 6.66663 20.0001C6.66663 26.9334 11.9666 32.6334 18.7333 33.2668C18.7 32.9834 18.6833 32.6834 18.6833 32.4001C18.6833 28.2001 22.0833 24.8001 26.2833 24.8001C28.2833 24.8001 30.1 25.5667 31.45 26.8334C32.65 24.8334 33.3333 22.5001 33.3333 20.0167C33.3333 12.6334 27.3666 6.66675 20 6.66675ZM15.3 23.9001C12.85 23.9001 10.85 21.9167 10.85 19.4501C10.85 16.9834 12.8333 15.0001 15.3 15.0001C17.75 15.0001 19.75 16.9834 19.75 19.4501C19.75 21.9167 17.75 23.9001 15.3 23.9001ZM22.4 14.0501C21.1166 14.0501 20.0833 13.0167 20.0833 11.7334C20.0833 10.4501 21.1166 9.41675 22.4 9.41675C23.6833 9.41675 24.7166 10.4501 24.7166 11.7334C24.7 13.0167 23.6666 14.0501 22.4 14.0501Z"
          fill="white"
        />
      </svg>
    </React.Fragment>
  ),
};

const SCANS = {
  id: "scans",
  htmlID: "footer-scans",
  name: "Chaos Scans",
  link: "/scans",
  target: "",
  logo: (
    <React.Fragment>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M40 0H0V40H40V0Z" fill="#9E3988" />
        <path
          d="M23.4332 26.4C23.5666 26.4167 23.6832 26.3667 23.7832 26.25L32.7999 16.25C32.6166 15.6334 32.3999 15.05 32.1333 14.4667L23.1666 25.7334C23.0166 25.9 23.0499 26.1667 23.2166 26.3C23.2832 26.3667 23.3499 26.4 23.4332 26.4Z"
          fill="white"
        />
        <path
          d="M29.3338 10.4835L19.0005 25.9502C18.8672 26.1335 18.9172 26.3835 19.1005 26.5168C19.1672 26.5668 19.2505 26.6002 19.3338 26.6002C19.4672 26.6002 19.5838 26.5335 19.6672 26.4335L30.6338 11.9668C30.2505 11.4502 29.8005 10.9502 29.3338 10.4835Z"
          fill="white"
        />
        <path
          d="M15.9835 26.4334L27.3002 8.85008C25.2002 7.46675 22.7002 6.66675 20.0002 6.66675C12.9335 6.66675 7.13353 12.1834 6.7002 19.1334L15.9835 26.4334Z"
          fill="white"
        />
        <path
          d="M20.0667 33.3333C27.3834 33.3 33.3167 27.35 33.3334 20.0167L20.0667 33.3333Z"
          fill="white"
        />
        <path
          d="M6.86686 22.2835C7.7502 27.4335 11.6002 31.5835 16.6002 32.9001L6.86686 22.2835Z"
          fill="white"
        />
      </svg>
    </React.Fragment>
  ),
};

const PHOENIX = {
  id: "phoenix",
  htmlID: "footer-phoenix",
  name: "Phoenix FD",
  link: "/fluid-dynamics-software",
  target: "",
  logo: (
    <React.Fragment>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M40 0H0V40H40V0Z" fill="#F3B830" />
        <path
          d="M33.3336 19.9999C33.3336 12.4666 27.0836 6.38325 19.4836 6.68325C12.6003 6.94991 6.9836 12.5332 6.6836 19.4166C6.5336 22.6999 7.5836 25.7332 9.41693 28.1166C9.71693 28.4999 10.0669 28.4333 10.2003 27.9666C11.1669 24.6499 13.6836 21.6332 16.2836 19.7332C15.2003 18.6332 14.8669 17.5999 14.6836 15.9999C15.1336 16.5166 15.8003 17.5666 17.1169 17.5166C14.6336 15.2499 15.0003 11.6666 15.0003 11.6666C15.0003 11.6666 16.0503 14.0166 18.4669 14.5499C16.8669 12.8499 16.0669 10.6999 17.2336 8.24991C17.4669 10.3832 18.9169 11.3832 20.4836 12.7332C21.1669 13.3166 21.9169 13.8499 22.6336 14.3999C24.4836 15.8499 26.4336 17.1999 28.1503 18.7832C29.6503 20.1666 30.2503 21.9999 29.8169 24.2499C27.8503 21.6166 24.6669 21.2166 22.5669 22.2832C20.5669 23.2999 19.6169 25.7499 20.3336 28.1833C20.6169 29.1333 21.1503 30.0333 22.1169 30.7999C22.4169 31.0333 22.7169 31.2333 23.0503 31.4166C24.3669 32.1166 25.9503 32.0333 27.2003 31.2166C30.9003 28.8333 33.3336 24.6999 33.3336 19.9999Z"
          fill="white"
        />
      </svg>
    </React.Fragment>
  ),
};

const VANTAGE = {
  id: "vantage",
  htmlID: "footer-vantage",
  name: "Vantage",
  link: "/vantage",
  target: "",
  logo: (
    <React.Fragment>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M40 0H0V40H40V0Z" fill="#77B22A" />
        <path
          d="M31.0668 12.8834C31.1502 12.8667 31.2001 12.7501 31.1502 12.6834C28.7668 9.06675 24.6668 6.66675 20.0001 6.66675C19.1835 6.66675 18.3835 6.75008 17.6168 6.88341C17.5335 6.90008 17.4835 7.00008 17.5335 7.08341C21.4168 14.0001 23.6668 14.9501 31.0668 12.8834Z"
          fill="white"
        />
        <path
          d="M16.6333 7.25008C16.6166 7.18342 16.5333 7.13342 16.4666 7.15008C10.8166 8.70008 6.66663 13.8668 6.66663 20.0001C6.66663 21.0667 6.79996 22.1168 7.03329 23.1001C7.04996 23.1834 7.14996 23.2334 7.23329 23.1834C16.7166 18.3501 18.9166 16.0668 16.6333 7.25008Z"
          fill="white"
        />
        <path
          d="M27.55 30.7667C27.5834 30.85 27.6834 30.8834 27.7667 30.8334C31.1334 28.4167 33.3334 24.4667 33.3334 20C33.3334 17.7667 32.7834 15.6667 31.8167 13.8167C31.7667 13.7167 31.6334 13.6834 31.5334 13.7334C24.5167 18.1667 24.5667 21.6834 27.55 30.7667Z"
          fill="white"
        />
        <path
          d="M7.43328 23.9501C7.34994 23.9834 7.29994 24.0834 7.31661 24.1667C9.06661 29.5001 14.0833 33.3334 19.9833 33.3334C22.4833 33.3334 24.8333 32.6334 26.8333 31.4334C26.9 31.3834 26.9333 31.2834 26.8999 31.2167C21.8999 21.3834 18.8499 20.1001 7.43328 23.9501Z"
          fill="white"
        />
        <path
          d="M21.6665 20.1001C22.9367 20.1001 23.9665 19.0703 23.9665 17.8001C23.9665 16.5298 22.9367 15.5001 21.6665 15.5001C20.3962 15.5001 19.3665 16.5298 19.3665 17.8001C19.3665 19.0703 20.3962 20.1001 21.6665 20.1001Z"
          fill="white"
        />
      </svg>
    </React.Fragment>
  ),
};

const CHAOS_PLAYER = {
  id: "chaos_player",
  htmlID: "footer-chaos_player",
  name: "Chaos Player",
  link: "/player",
  target: "",
  logo: (
    <React.Fragment>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
      >
        <g>
          <g>
            <path fill="#12988B" d="M0,0v40h40V0H0z" />
          </g>
          <path
            fill="#FFFFFF"
            d="M20,6.6666017C12.6363277,6.6666017,6.6666017,12.6361332,6.6666017,20
  c0,7.3637695,5.9697261,13.3333015,13.3333988,13.3333015S33.3333969,27.3637695,33.3333969,20
  C33.3333969,12.6361332,27.3636723,6.6666017,20,6.6666017z M24.2765617,23.1620121
  c0,0.1311512-0.076952,0.2501945-0.1964836,0.3040028l-10.1980467,4.5264664
  c-0.1738281,0.0782223-0.3181639,0.0067368-0.3181639-0.1577148V12.2224607c0-0.1658201,0.1474609-0.3037109,0.3212891-0.222949
  l10.1986332,4.7428703c0.1175766,0.0546875,0.1927719,0.172657,0.1927719,0.3023453V23.1620121z M29.7261715,20.924902
  l-3.2867184,1.4792004c-0.1130867,0.0509758-0.2412109-0.0318375-0.2412109-0.1559582v-4.2547855
  c0-0.125,0.1298828-0.2078114,0.2431641-0.155077l3.2943363,1.53125
  C30.3998051,19.6782227,30.3941402,20.6243172,29.7261715,20.924902z"
          />
        </g>
      </svg>
    </React.Fragment>
  ),
};

const CHAOS = {
  id: "chaos",
  htmlID: "footer-chaos",
  name: "Chaos Cloud",
  link: "/cloud-rendering",
  target: "",
  logo: (
    <React.Fragment>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M40 0H0V40H40V0Z" fill="#17A2DF" />
        <path
          d="M23.15 7.05014C16.7167 5.4668 10 8.9668 7.58334 15.1501C6.95001 16.7835 6.65001 18.4501 6.66667 20.0835C6.66667 20.2168 6.81667 20.3001 6.91667 20.2168C7.61667 19.7668 8.45001 19.4835 9.33334 19.4835C9.35001 19.4835 9.38334 19.4835 9.40001 19.4835C9.40001 19.1001 9.45001 18.7001 9.56667 18.3001C10.2167 16.3001 12.3667 15.1668 14.3833 15.8001C14.5833 15.8668 14.7667 15.9501 14.95 16.0335C16.4 13.1335 19.7667 11.6168 22.9667 12.6001C26.3 13.6335 28.25 17.0335 27.55 20.3835C27.8833 20.4168 28.2333 20.4835 28.5667 20.5835C30.5667 21.2001 31.8667 23.0168 31.9333 25.0001C31.9333 25.2168 32.2333 25.2668 32.3167 25.0668C32.4667 24.7168 32.6 24.3501 32.7167 23.9668C35 16.6668 30.6833 8.90014 23.15 7.05014Z"
          fill="white"
        />
        <path
          d="M26.9165 24.3168C26.4499 24.3001 25.9999 24.3668 25.5832 24.5334C25.5832 24.4501 25.5999 24.3834 25.5999 24.3001C25.7332 21.5001 23.5499 19.1334 20.7499 19.0001C18.0165 18.8834 15.7165 20.9334 15.4665 23.6168C14.9332 23.3001 14.3165 23.1001 13.6499 23.0668C11.5332 22.9668 9.7332 24.6168 9.6332 26.7334C9.5832 27.9001 10.0499 29.0001 10.8665 29.7334C11.0332 29.8834 11.1832 30.0168 11.1832 30.0168C12.5499 31.2001 14.1832 32.1501 16.0165 32.7334C20.3165 34.0668 24.7832 33.1168 28.0999 30.5668C28.6665 30.1334 29.1165 29.7501 29.3999 29.3834C29.7832 28.9001 30.0165 28.2834 30.0499 27.6334C30.1332 25.8668 28.7332 24.3834 26.9165 24.3168Z"
          fill="white"
        />
      </svg>
    </React.Fragment>
  ),
};

const ENSCAPE = {
  id: "enscape",
  htmlID: "footer-enscape",
  name: "Enscape",
  link: "https://www.enscape3d.com/",
  target: "_blank",
  logo: (
    <React.Fragment>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="40"
        height="40"
        viewBox="0 0 100 100"
        // style="enable-background:new 0 0 100 100;"
        xmlSpace="preserve"
      >
        <g id="Layer_1" />
        <g id="Isolation_Mode">
          <g>
            <path fill="#F7941D" d="M0,0v100h100V0H0z" />
            <path
              fill="#FFFFFF"
              d="M79.8082809,41.4213333L54.2136993,62.7873344
			c-2.4428711,2.0402794-5.9786453,2.0413246-8.4235992,0.0030212L29.7151413,49.384449
			c-0.640831-0.5345726-0.9316235-1.3834572-0.7541237-2.2019997l1.2324524-5.6832771
			c0.1363678-0.6275826,0.8653984-0.9085312,1.3828983-0.5335846l14.8545628,10.7825203
			c2.1339035,1.5481415,5.011097,1.5481415,7.1440163-0.0030212l23.5458794-17.1146011
			c0.2276917-0.1657295,0.3219452-0.474987,0.2316055-0.7528534l-2.5450821-7.7862816
			c-0.0601044-0.1829395-0.198555-0.3304749-0.3781357-0.4001713l-22.0125351-8.5704689
			c-1.5524406-0.6053162-3.273201-0.6053162-4.8264961-0.0009861l-22.0226364,8.571455
			c-0.1785393,0.0696964-0.3179054,0.2172318-0.3780727,0.4001713l-8.4887848,26.0064125
			c-0.0801792,0.2455444-0.0160351,0.5144043,0.1655025,0.6972809L45.144371,81.2682419
			c2.7356834,2.7537155,6.9834137,2.7537155,9.7181168-0.0010452l28.2771378-28.4802322
			c0.1765213-0.1778831,0.2387085-0.4395866,0.1604843-0.6780968L79.8082809,41.4213333z M47.8228874,27.2974205
			c1.3909798-0.6457157,2.9693718-0.6457157,4.3612061,0l9.7723465,4.5353107l-9.3652,5.6793308
			c-1.6075287,0.9741554-3.5689507,0.9741554-5.1764755,0l-9.3642197-5.6793308L47.8228874,27.2974205z"
            />
          </g>
        </g>
      </svg>
    </React.Fragment>
  ),
};

const CYLINDO = {
  id: "cylindo",
  htmlID: "footer-cylindo",
  name: "Cylindo",
  link: "https://www.cylindo.com/",
  target: "_blank",
  logo: (
    <React.Fragment>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="40"
        height="40"
        viewBox="0 0 100 100"
        // style="enable-background:new 0 0 100 100;"
        xmlSpace="preserve"
      >
        <g id="Layer_1" />
        <g id="Isolation_Mode">
          <g>
            <path fill="#00B0BD" d="M0,0v100h100V0H0z" />
            <g>
              <path
                fill="#FFFFFF"
                d="M50,16.6660156C31.5898438,16.6660156,16.6660156,31.590332,16.6660156,50
				c0,18.4091797,14.9238281,33.3330078,33.3339844,33.3330078c18.4091797,0,33.3330078-14.9238281,33.3330078-33.3330078
				C83.3330078,31.590332,68.4091797,16.6660156,50,16.6660156z"
              />
              <path
                fill="#00B0BD"
                d="M61.2988281,66.4140625C53.6176758,64.9077148,47.815918,58.1318359,47.815918,50
				c0-8.1323242,5.8017578-14.9091797,13.4829102-16.4150391c0.3681641-0.0722656,0.6376953-0.3842773,0.6376953-0.7592773
				V27.362793c0-0.3398438-0.2167969-0.6469727-0.5405273-0.7490234c-2.0844727-0.6621094-4.2792969-1.046875-6.5571289-1.1230469
				c-12.9121094-0.4257812-24.1611328,9.4838867-25.2592773,22.355957
				c-1.237793,14.5170898,10.1831055,26.675293,24.4301758,26.675293c2.5761719,0,5.0507812-0.3950195,7.387207-1.1362305
				c0.3227539-0.1030273,0.5395508-0.4101562,0.5395508-0.7490234v-5.4638672
				C61.9365234,66.7978516,61.6669922,66.4858398,61.2988281,66.4140625z"
              />
              <path
                fill="#00B0BD"
                d="M61.8813477,38.7011719C57.1083984,40.237793,53.6494141,44.7241211,53.6494141,50
				c0,5.2749023,3.4589844,9.7617188,8.2319336,11.2978516c0.5068359,0.1630859,1.0288086-0.2109375,1.0288086-0.7441406V39.4448242
				C62.9101562,38.9130859,62.3881836,38.5380859,61.8813477,38.7011719z"
              />
            </g>
          </g>
        </g>
      </svg>
    </React.Fragment>
  ),
};

const PRODUCTS = [
  CORONA,
  ENSCAPE,
  PHOENIX,
  VANTAGE,
  CHAOS_PLAYER,
  CYLINDO,
  CHAOS,
  VRAY,
  COSMOS,
  SCANS,
];

export default PRODUCTS;
