import React from "react";
import fs from "../Footer/Footer.module.css";
import SlimFooterPlaceholder from "../SlimFooter/Placeholder";
import LegalFooterPlaceholder from "../LegalFooter/Placeholder";

const HeaderPlaceholder = ({ locale }) => (
  <div className={fs.footer}>
    <SlimFooterPlaceholder locale={locale} />

    <LegalFooterPlaceholder />
  </div>
);

export default HeaderPlaceholder;
