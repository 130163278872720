import React from "react";
import s from "./Hamburger.module.css";

export default function Hamburger({ open, onClick }) {
  return (
    <button
      type="button"
      className={`${s.hamburger} ${s["hamburger--squeeze"]} ${
        open && s["is-active"]
      }`}
      onClick={onClick}
    >
      <span className={s["hamburger-box"]}>
        <span className={s["hamburger-inner"]} />
      </span>
    </button>
  );
}
