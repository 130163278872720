import React from "react";
import LegalFooter from "../LegalFooter";
import s from "../Footer/Footer.module.css";

export default function LandingFooter({ data }) {
  return (
    <div className={s.footer}>
      <LegalFooter copyright={data.copyright} links={data.slim_links} />
    </div>
  );
}
