import React from "react";
import SlimFooter from "../SlimFooter";
import LegalFooter from "../LegalFooter";
import s from "../Footer/Footer.module.css";

const DOMAIN = "https://www.chaos.com";

export default function LandingFooter({ locale, data, visibleProducts }) {
  return (
    <div className={s.footer}>
      <SlimFooter
        locale={locale}
        domain={DOMAIN}
        visibleProducts={visibleProducts}
      />
      <LegalFooter copyright={data.copyright} links={data.slim_links} />
    </div>
  );
}
