import React from "react";
import PlaceholderRectangle from "../PlaceholderRectangle";
import lfs from "./LegalFooter.module.css";

import Container from "../Container";

const FONT_HEIGHT = "2em";
const TEXT_WIDTH = "50px";
const HSTACK = { display: "flex", gap: "1em" };

const Placeholder = () => (
  <div className={lfs["legal-wrapper"]}>
    <Container>
      <div className={lfs.legal}>
        <div className={lfs.copyright}>
          <PlaceholderRectangle style={{ width: "400px", height: "34px" }} />
        </div>
        <div style={HSTACK}>
          <PlaceholderRectangle
            style={{ width: TEXT_WIDTH, height: FONT_HEIGHT }}
          />
          <PlaceholderRectangle
            style={{ width: TEXT_WIDTH, height: FONT_HEIGHT }}
          />
          <PlaceholderRectangle
            style={{ width: TEXT_WIDTH, height: FONT_HEIGHT }}
          />
          <PlaceholderRectangle
            style={{ width: TEXT_WIDTH, height: FONT_HEIGHT }}
          />
          <PlaceholderRectangle
            style={{ width: TEXT_WIDTH, height: FONT_HEIGHT }}
          />
        </div>
      </div>
    </Container>
  </div>
);

export default Placeholder;
