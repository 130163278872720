import React from "react";
import fs from "../Footer/Footer.module.css";

import LegalFooterPlaceholder from "../LegalFooter/Placeholder";

const HeaderPlaceholder = () => (
  <div className={fs.footer}>
    <LegalFooterPlaceholder />
  </div>
);

export default HeaderPlaceholder;
