import React from "react";

function gtag() {
  window.dataLayer.push(arguments);
}

const pickMenu = (menus, variant) =>
  (menus.find((menu) => menu.go_variant === variant) || { menu: [] }).menu;

const withOptimize = (component, Placeholder) => {
  const Component = component;

  const Wrapper = (props) => {
    const { data, ...rest } = props;
    const [variant, setVariant] = React.useState(null);

    const goCallback = (value) => {
      console.log("Google Optimize - ", value);
      setVariant(value);
    };

    React.useEffect(() => {
      if (!data.google_optimize_exp_id) {
        return;
      }

      if (typeof window !== "undefined" && window.dataLayer) {
        gtag("event", "optimize.callback", {
          name: data.google_optimize_exp_id,
          callback: goCallback,
        });
      } else {
        console.warn("Header: No dataLayer found to enable Optimize!");
      }

      return () => {
        gtag("event", "optimize.callback", {
          name: data.google_optimize_exp_id,
          callback: goCallback,
          remove: true,
        });
      };
    }, []);

    if (data.google_optimize_exp_id) {
      if (variant === null) {
        return <Placeholder />;
      }

      const newData = {
        ...data,
        mega_menu: pickMenu(data.mega_menus, variant),
      };

      return <Component data={{ ...newData }} {...rest} />;
    } else {
      return <Component data={{ ...data }} {...rest} />;
    }
  };

  return Wrapper;
};

export default withOptimize;
