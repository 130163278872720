import React from "react";

const ClickAway = ({ children, onAwayClick }) => {
  const wrapperRef = React.useRef(null);

  React.useEffect(() => {
    const handleClickAway = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onAwayClick();
      }
    };

    document.addEventListener("mousedown", handleClickAway);

    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, [wrapperRef]);

  return <div ref={wrapperRef}>{children}</div>;
};

export default ClickAway;
