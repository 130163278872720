import React from "react";
import { CartContext, extractCartInfo } from "./CartUtils";

const Link = ({ settings, children }) => {
  const cart = React.useContext(CartContext);
  const { productCode, quantity } = extractCartInfo(settings.link);

  return (
    <a
      href={settings.link}
      title={settings.label}
      className={settings.className}
      target={settings.target}
      id={settings.id}
      onClick={(e) => {
        if (cart && cart.add && productCode && quantity) {
          e.preventDefault();
          cart.add(productCode, parseInt(quantity));
        }
      }}
    >
      {children || settings.label}
    </a>
  );
};

export default Link;
