import React from "react";
import Spacing from "../Spacing";
import Container from "../Container";
import ButtonClose from "../ButtonClose";
import s from "./Search.module.css";

const Search = ({ visible, search, onClose }) => (
  <div className={`${s.search} ${visible ? s.open : ""}`}>
    <ButtonClose onClick={onClose} />

    <Spacing>
      <Container>
        <div className={s["search-wrapper"]}>
          <h3 className={s.title}>{search.title}</h3>

          <form action={search.link.link} method="get">
            <input
              type="text"
              name="query"
              placeholder={search.placeholder}
              className={s.input}
            />
          </form>
        </div>
      </Container>
    </Spacing>
  </div>
);

export default Search;
