import React from "react";
import { FlexContainer, FlexItem } from "../Flex";
import Container from "../Container";
import Spacing from "../Spacing";
import SlimFooterPlaceholder from "../SlimFooter/Placeholder";
import LegalFooterPlaceholder from "../LegalFooter/Placeholder";
import PlaceholderRectangle from "../PlaceholderRectangle";
import PreFooterPlaceholder from "../PreFooter/Placeholder";
import fs from "./Footer.module.css";

const FONT_HEIGHT = "2em";
const TEXT_WIDTH = "100px";
const VSTACK = { display: "flex", flexDirection: "column", gap: "1em" };

const LinkColumn = () => (
  <div style={VSTACK}>
    <PlaceholderRectangle style={{ width: TEXT_WIDTH, height: FONT_HEIGHT }} />
    <PlaceholderRectangle style={{ width: TEXT_WIDTH, height: FONT_HEIGHT }} />
    <PlaceholderRectangle style={{ width: TEXT_WIDTH, height: FONT_HEIGHT }} />
    <PlaceholderRectangle style={{ width: TEXT_WIDTH, height: FONT_HEIGHT }} />
    <PlaceholderRectangle style={{ width: TEXT_WIDTH, height: FONT_HEIGHT }} />
    <PlaceholderRectangle style={{ width: TEXT_WIDTH, height: FONT_HEIGHT }} />
    <PlaceholderRectangle style={{ width: TEXT_WIDTH, height: FONT_HEIGHT }} />
    <PlaceholderRectangle style={{ width: TEXT_WIDTH, height: "3px" }} />
  </div>
);

const FooterPlaceholder = ({ locale, hidePreFooter }) => (
  <div className={fs.footer}>
    {!hidePreFooter && <PreFooterPlaceholder />}
    <SlimFooterPlaceholder locale={locale} />

    <Spacing s="both-sm">
      <Container>
        <FlexContainer>
          <FlexItem>
            <LinkColumn />
          </FlexItem>

          <FlexItem>
            <LinkColumn />
          </FlexItem>

          <FlexItem>
            <LinkColumn />
          </FlexItem>

          <FlexItem>
            <LinkColumn />
          </FlexItem>

          <FlexItem>
            <LinkColumn />
          </FlexItem>

          <FlexItem>
            <div className={fs.links}>
              <PlaceholderRectangle
                style={{
                  width: TEXT_WIDTH,
                  height: FONT_HEIGHT,
                  marginBottom: "1em",
                }}
              />

              <div className={fs.social}>
                <PlaceholderRectangle
                  style={{ width: "24px", height: FONT_HEIGHT }}
                />
                <PlaceholderRectangle
                  style={{ width: "24px", height: FONT_HEIGHT }}
                />
                <PlaceholderRectangle
                  style={{ width: "24px", height: FONT_HEIGHT }}
                />
                <PlaceholderRectangle
                  style={{ width: "24px", height: FONT_HEIGHT }}
                />
                <PlaceholderRectangle
                  style={{ width: "24px", height: FONT_HEIGHT }}
                />
              </div>
            </div>
          </FlexItem>
        </FlexContainer>
      </Container>
    </Spacing>

    <LegalFooterPlaceholder />
  </div>
);

export default FooterPlaceholder;
