import React from "react";

const Title = ({ title }) => <h4>{title}</h4>;

const HTML = ({ code }) => <div dangerouslySetInnerHTML={{ __html: code }} />;

const WIDGETS = {
  Title,
  HTML,
};

export default WIDGETS;
