import React from "react";

const SLIM_ICONS = {
  globe: {
    type: "svg",
    height: "16px",
    width: "16px",
    icon: (
      <React.Fragment>
        <svg
          width="16"
          height="16"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
            stroke="#1C1C1C"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 10H19"
            stroke="#1C1C1C"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.0004 1C12.2515 3.46452 13.5309 6.66283 13.6004 10C13.5309 13.3372 12.2515 16.5355 10.0004 19C7.74924 16.5355 6.46991 13.3372 6.40039 10C6.46991 6.66283 7.74924 3.46452 10.0004 1V1Z"
            stroke="#1C1C1C"
            strokeWidth="1.3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </React.Fragment>
    ),
  },
  user: {
    type: "svg",
    height: "16px",
    width: "15px",
    icon: (
      <React.Fragment>
        <svg
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              d="M7.50122 1.33588C7.03108 1.31808 6.56218 1.39532 6.12258 1.56296C5.68298 1.73061 5.28172 1.98521 4.94281 2.31154C4.6039 2.63787 4.3343 3.02921 4.15015 3.46216C3.96601 3.8951 3.87109 4.36075 3.87109 4.83123C3.87109 5.30171 3.96601 5.76735 4.15015 6.20029C4.3343 6.63324 4.6039 7.02458 4.94281 7.35091C5.28172 7.67724 5.68298 7.93184 6.12258 8.09949C6.56218 8.26713 7.03108 8.34437 7.50122 8.32657C7.97136 8.34437 8.44026 8.26713 8.87986 8.09949C9.31946 7.93184 9.72072 7.67724 10.0596 7.35091C10.3985 7.02458 10.6681 6.63324 10.8523 6.20029C11.0364 5.76735 11.1313 5.30171 11.1313 4.83123C11.1313 4.36075 11.0364 3.8951 10.8523 3.46216C10.6681 3.02921 10.3985 2.63787 10.0596 2.31154C9.72072 1.98521 9.31946 1.73061 8.87986 1.56296C8.44026 1.39532 7.97136 1.31808 7.50122 1.33588V1.33588Z"
              stroke="#1C1C1C"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M13.4865 14.6667V14.3114C13.4865 10.9937 10.7693 8.32654 7.5016 8.32654C4.23394 8.32654 1.51672 10.9687 1.51672 14.2864V14.6367"
              stroke="#1C1C1C"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect
                width="15"
                height="15"
                fill="white"
                transform="translate(0 0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      </React.Fragment>
    ),
  },
  help: {
    type: "b64",
    icon:
      "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHdpZHRoPSIxNXB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNSAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTUgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiMxQzFDMUM7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO30NCgkuc3Qxe2ZpbGw6IzFDMUMxQzt9DQoJLnN0MntmaWxsOm5vbmU7c3Ryb2tlOiMxQzFDMUM7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO30NCjwvc3R5bGU+DQo8Zz4NCgk8Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSI3LjUiIGN5PSI4IiByPSI2LjIiLz4NCgk8Y2lyY2xlIGNsYXNzPSJzdDEiIGN4PSI3LjUiIGN5PSIxMS40IiByPSIwLjYiLz4NCgk8cGF0aCBjbGFzcz0ic3QyIiBkPSJNNi4zLDQuOWMxLjEtMC41LDIuNi0wLjQsMy4xLDAuNEM5LjksNi4xLDkuNSw3LDguNyw3LjdTNy41LDguOSw3LjUsOS40Ii8+DQo8L2c+DQo8L3N2Zz4NCg==",
  },
  forum: {
    type: "b64",
    icon:
      "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjc0OTggNy4zNzVDMTMuNzQ5OCAxMC40ODE2IDExLjIzMTQgMTMgOC4xMjQ4MiAxM0M3LjI2OTA5IDEzIDYuNDU3OTkgMTIuODA4OSA1LjczMTg2IDEyLjQ2NzFDNC4yNjc2MSAxMy4xNzcgMi44MzEyNiAxMy44MDY4IDEuMjA1NTcgMTQuMjk0NEMxLjY5MzE0IDEyLjY2ODcgMi4zMjI5NSAxMS4yMzI0IDMuMDMyODUgOS43NjgyQzIuNjkwOTUgOS4wNDIwMSAyLjQ5OTgyIDguMjMwODMgMi40OTk4MiA3LjM3NUMyLjQ5OTgyIDQuMjY4NCA1LjAxODIxIDEuNzUgOC4xMjQ4MiAxLjc1QzExLjIzMTQgMS43NSAxMy43NDk4IDQuMjY4NCAxMy43NDk4IDcuMzc1WiIgc3Ryb2tlPSIjMUMxQzFDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==",
  },
  downloads: {
    type: "b64",
    icon:
      "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNSAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuODc1IDkuODc1VjEzLjYyNUgxMy4xMjVWOS44NzUiIHN0cm9rZT0iIzFDMUMxQyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik00LjM3NSA2Ljc1TDcuNSA5Ljg3NUwxMC42MjUgNi43NSIgc3Ryb2tlPSIjMUMxQzFDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTcuNSA5Ljg3NVYyLjM3NSIgc3Ryb2tlPSIjMUMxQzFDIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==",
  },
  mobileUser: {
    type: "b64",
    icon:
      "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjkzNDMgMTMuMDEyNkMxMy41ODI1IDEyLjIyMzkgMTMuMDY2OSAxMS41MDc0IDEyLjQxNjkgMTAuOTAzOEMxMS43NjY5IDEwLjMwMDIgMTAuOTk1MiA5LjgyMTQ0IDEwLjE0NTkgOS40OTQ3OEM5LjI5NjY2IDkuMTY4MTMgOC4zODY0MSA5IDcuNDY3MTYgOUM2LjU0NzkgOSA1LjYzNzY1IDkuMTY4MTMgNC43ODgzNyA5LjQ5NDc4QzMuOTM5MDkgOS44MjE0NCAzLjE2NzQyIDEwLjMwMDIgMi41MTc0MSAxMC45MDM4QzEuODY3NCAxMS41MDc0IDEuMzUxNzggMTIuMjIzOSAxIDEzLjAxMjYiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS41Ii8+CjxjaXJjbGUgY3g9IjcuNDY3MjkiIGN5PSI1IiByPSI0IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8L3N2Zz4K",
  },
  check: {
    type: "b64",
    icon:
      "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxNCAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzIDEuNUw0Ljc1IDkuNzVMMSA2IiBzdHJva2U9IiMwREI1RkYiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==",
  },
};

export default SLIM_ICONS;
