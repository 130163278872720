import React from "react";
import Link from "../Link";
import { CartContext, extractCartInfo } from "../CartUtils";
import s from "./Button.module.css";

const Icon = ({ src }) => (
  <div className={s.icon}>
    {src && <img src={src.urls.original} alt={src.title} />}
  </div>
);

const hasMenu = (menu) => menu && menu.length > 0;

const Button = ({ settings, menu }) => {
  const cart = React.useContext(CartContext);
  const { productCode, quantity } = extractCartInfo(settings.link);

  return (
    <div className={`${s.wrapper} ${menu.length > 0 ? s["has-submenu"] : ""}`}>
      <a
        href={settings.link}
        id={settings.id}
        rel={settings.rel}
        target={settings.target}
        className={s[settings.type]}
        onClick={(e) => {
          if (hasMenu(menu)) {
            e.preventDefault();
          }

          if (!hasMenu(menu) && cart && cart.add && productCode && quantity) {
            e.preventDefault();
            cart.add(productCode, parseInt(quantity));
          }
        }}
        style={hasMenu(menu) ? { cursor: "default" } : {}}
      >
        {settings.label}
      </a>

      {hasMenu(menu) && (
        <div className={s.sub}>
          {menu.map((l) => (
            <Link key={l.id} settings={l.link}>
              <Icon src={l.icon} />

              {l.link.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const SimpleButton = ({ settings }) => (
  <a
    href={settings.link}
    id={settings.id}
    rel={settings.rel}
    target={settings.target}
    className={s[settings.type]}
  >
    {settings.label}
  </a>
);

export { Button, SimpleButton };
