import React from "react";
import s from "./ButtonClose.module.css";

const ButonClose = ({ onClick }) => (
  <button type="button" className={s["btn-close"]} onClick={onClick}>
    Close search
  </button>
);

export default ButonClose;
