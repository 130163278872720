import React from "react";
import Container from "../Container";
import HubspotForm from "./HubspotForm";
import s from "./PreFooter.module.css";

const PreFooter = ({ newsletter, hubspotData, user }) => (
  <div className="pre-footer">
    <Container>
      <div className={s["pre-footer-wrapper"]}>
        <div className={s["text-wrapper"]}>
          <h3 className={s.title}>{newsletter?.title}</h3>

          <div
            className={s.description}
            dangerouslySetInnerHTML={{ __html: newsletter?.description }}
          />
        </div>

        <HubspotForm hubspotData={hubspotData} user={user} />
      </div>
    </Container>
  </div>
);

export default PreFooter;
