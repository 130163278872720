import React from "react";
import s from "./PreFooter.module.css";

const ID = "chaos-footer-newsletter-hubspot-form-xdwdkl";

const HubSpotForm = ({ hubspotData, user }) => {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: hubspotData.portal_id,
          formId: hubspotData.form_id,
          region: hubspotData.region,
          target: `#${ID}`,
          onFormReady: (_form) => {
            const input = document.getElementById(
              `email-${hubspotData.form_id}`,
            );

            input.value = user?.email || "";
          },
        });
      }
    });
  }, []);

  return <div className={s["hubspot-overrides"]} id={ID} />;
};

export default HubSpotForm;
