import React from "react";
import s from "./Flex.module.css";

function FlexItem({ children }) {
  return <div className={s["flex-item"]}>{children}</div>;
}

function FlexContainer({ children }) {
  return <div className={s["flex-container"]}>{children}</div>;
}

function FixedFlexItem({ children }) {
  return <div className={s["fixed-flex-item"]}>{children}</div>;
}

function WrappableFlexContainer({ children }) {
  return <div className={s["wrappable-flex-container"]}>{children}</div>;
}

export { FlexContainer, FlexItem, WrappableFlexContainer, FixedFlexItem };
